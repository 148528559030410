import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/core/service/base.service';
import { ApprovalRequest, LeadCbPaging } from '../model/lead-cb';

@Injectable({
  providedIn: 'root'
})
export class LeadCbService extends BaseService {

  private currentPage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private actionPage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentPage$: Observable<string>;
  actionPage$: Observable<string>;
  constructor(private http: HttpClient) {
    super();
    this.currentPage$ = this.currentPage.asObservable();
    this.actionPage$ = this.currentPage.asObservable();
  }

  getHttp(): HttpClient {
    return this.http;
  }

  getServiceName(): string {
    return 'LeadCbService';
  }

  setPage(page: '' | 'viewLeadCb' | any) {
    this.currentPage.next(page);
  }
  setAction(action: any) {
    this.actionPage.next(action);
  }
  checkDataImport(file: FormData): Observable<any> {
    return this.doPost('/lead-cb/portal/import', file).pipe(
      map(res => res.data ? res.data : {})
    );
  }
  afterSuccessImport(importFile: any[]) {
    return this.doPost('/lead-cb/portal/addList', importFile).pipe(
      map(res => res ? res : {})
    );
  }
  listFilter(filter: any, isApproval: boolean): Observable<LeadCbPaging> {
    if (isApproval) {
      return this.doPost('/lead-cb/portal/getLeadCBWaitingApprove', filter).pipe(
        map(res => res.data[0])
      );
    }
    return this.doPost('/lead-cb/search-lead', filter).pipe(
      map(res => res.data[0])
    );
  }
  detail(id: any) {
    return this.doPost('/lead-cb/lead-detail', { id }).pipe(
      map(res => res ? res : {})
    );
  }
  detailLead(id: any) {
    return this.doPost('/lead-cb/portal/detail', { id }).pipe(
      map(res => res ? res : {})
    );
  }
  commonLead(id: any) {
    return this.doPost('/lead-cb/lead-common-detail', { leadId: id }).pipe(
      map(res => res ? res : {})
    );
  }
  action(approval: ApprovalRequest[]): Observable<any> {
    return this.doPost('/lead-cb/portal/approve', approval).pipe(
      map(res => res ? res : {})
    );
  }
  leadCboInCharge() {
    return this.doPost('/lead-cb/lead-cbo', {}).pipe(
      map(res => res ? res.data : {})
    );
  }
  deleteLead(listId) {
    return this.doPost('/lead-cb/portal/deleteByAccount', listId).pipe(
      map(res => res ? res : {})
    );
  }
  getListSaleStage() {
    return this.doPost('/lead-cb/mobile/get-list-sale-stage', {}).pipe(
      map(res => res ? res.data[0] : {})
    );
  }
  doAlotment(body) {
    return this.doPost('/lead-cb/portal/updateListIDByAssignTo', body).pipe(
      map(res => res ? res : {})
    );
  }
  getUserByReport(body) {
    return this.doPost('/lead-cb/crm/get-user-by-reportto', body).pipe(
      map(res => res ? res.data[0] : {})
    );
  }
  getApiCrm(url, body) {
    return this.doPost(`/lead-cb/crm/${url}`, body).pipe(
      map(res => res ? res.data[0] : {})
    );
  }
  listExport(filter: any, isDetail: boolean): Observable<any> {
    if (isDetail) {
      return this.postDataBlob('/lead-cb/portal/exportOverViewExcel', filter);
    }
    return this.postDataBlob('/lead-cb/portal/exportDetailedExcel', filter);
  }
  listSaleIndustry() {
    return this.doPost(`/lead-cb/mobile/get-list-sale-industry`, null).pipe(
      map(res => res ? res.data[0] : {})
    );
  }
  getGrandCreditLimit() {
    return this.doPost('/lead-cb/mobile/get-list-grant-credit-limit', {}).pipe(
      map(res => res ? res.data[0] : {})
    );
  }
}
