export enum NewsEnum {
  STATUS_DRAFT = 0,
  STATUS_PUBLISHED = 1,
  STATUS_PENDING_PUBLISH = 2,
  LEVEL_NORMAL = 'BINHTHUONG',
  LEVEL_IMPORTANT = 'QUANTRONG',
  LEVEL_VERY_IMPORTANT = 'RATQUANTRONG',
  GROUP_VIEW_UNIT = 'UNIT',
  GROUP_VIEW_ROLE = 'ROLE',
  GROUP_VIEW_BRANCH = 'BRANCH',
  STATUS_DELETED = 10,
  PUBLISH_NOW = 1,
  PUBLISH_SCHEDULE = 0,
  GROUP_VIEW_PERSON = 'PERSON',
  APPROVED = 2,
  DISAPPROVED = 0,
  ACTIVE = 1,
  INACTIVE = 2
}

export enum CommentEnum {
  CMT = 'CMT',
  REP = 'REP'
}
