import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/core/service/base.service';
import { Unit } from '../model/unit';

@Injectable({
  providedIn: 'root'
})
export class CheckingBehavorService extends BaseService {
  getHttp(): HttpClient {
    return this.http;
  }

  getServiceName(): string {
    return 'UnitService';
  }

  constructor(private http: HttpClient) {
    super();
  }

  checkingBehavior(request: any): Observable<any> {
    return this.doPost('/tracking-behavior/portalCreateBehavior', request).pipe(map(res => res ? res : []));
  }

}
